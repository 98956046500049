import React from 'react';

import TopNav from './components/TopNav'
import Footer from './components/Footer'
import ViewSwitch from './components/ViewSwitch';
import { Layout } from 'antd'

import 'antd/dist/antd.css';
import './App.css';

import { Provider } from './store'

function App() {
  return (
    <Provider>
      <Layout className="App">
        <Layout.Header>
          <TopNav />
        </Layout.Header>
        <Layout.Content>
          <ViewSwitch />
        </Layout.Content>
        <Layout.Footer><Footer /></Layout.Footer>
      </Layout>
    </Provider>
  );
}

export default App;
