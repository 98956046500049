import { useEffect, useState } from 'react'
import { ConfigApi } from '../api'

export default function useConfig() {
  let [list, set] = useState();
  useEffect(() => {
    ConfigApi.getDomainList().then(data => {
      set(data);
    })
  }, [set]);
  return list ? list : false;
}