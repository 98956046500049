import React, { useMemo, useEffect } from 'react';
import { Menu } from 'antd';
import { useSelector } from '../../hooks';

import "./index.css"

const { SubMenu } = Menu;
export default function () {
  let siteInfo = useSelector(state => state.domain ? state.domain[0] : false);
  let view = useSelector(state => state.view);
  let [current, setMenu] = useMemo(()=>view ? view : [false, false], [view])
  useEffect(()=>{
    if(setMenu) {
      setMenu("1")
    }
  }, [setMenu])

  const handleClick = e => setMenu(e.key);
  return (
    <>
      <div className="ba-page-logo">{siteInfo && siteInfo.title}</div>
      <Menu
        theme="dark"
        mode="horizontal"
        style={{ lineHeight: '64px' }}
        onClick={handleClick}
        selectedKeys={[current]}
      >
        <Menu.Item key="1">主页</Menu.Item>
        <SubMenu title="系统" className="bg-menu-system">
          <Menu.Item key="2">登录</Menu.Item>
        </SubMenu>
      </Menu>
    </>
  );
}

