import React from 'react';
import AppCtx from './app_ctx'
import useDomain from './domain'
import useViewState from './view'

export default function Provider(props) {
  let domain = useDomain();
  let view = useViewState();
  let store = { domain, view };
  return (
    <AppCtx.Provider value={store}>
      {props.children}
    </AppCtx.Provider>
  )
}