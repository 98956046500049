import React, { useMemo, useEffect } from 'react'
import { useSelector, useDomain } from '../hooks'

import HomwView from './HomeView'
import LoginView from './LoginView'

export default () => {
  let view = useSelector(state => state.view);
  let viewId = useMemo(() => view ? view[0] : false, [view]);
  let siteInfo = useDomain(window.location.host);

  useEffect(() => {
    if (siteInfo) {
      document.title = siteInfo.title;
      siteInfo.image && (document.body.style.backgroundImage = "url(" + siteInfo.image + ")");
    }
  }, [siteInfo]);

  let _content = null;
  switch (viewId) {
    case "1":
      _content = <HomwView />;
      break;
    case "2":
      _content = <LoginView />;
      break;
    default: break;
  }
  return _content;
}