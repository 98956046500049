import Axios from 'axios'

class ConfigApi {
  getDomainList() {
    return new Promise(resolve => {
      Axios.get('/frontweb/config.json')
        .then(
          res => {
            resolve(res.data);
          },
          err => {
            console.log(err)
          }
        )
    })
  }
  getDomainInfo(url) {
    return new Promise(resolve => {
      Axios.get(url)
        .then(
          res => {
            resolve(res.data);
          },
          err => {
            console.log(err)
          }
        )
    })
  }
}

export default new ConfigApi();