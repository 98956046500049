import { useEffect } from 'react'
import useSelector from './useSelector'
import useConfig from './useConfig';

export default function useDomain(host) {
  let list = useConfig();
  let [info, set] = useSelector(store => {
    let domain = store.domain;
    if (domain && domain.length > 0) {
      return domain;
    }
    return [false, false];
  });
  useEffect(() => {
    if (list && set) {
      for (let idx in list) {
        let _info = list[idx];
        let reg = new RegExp("^" + _info.domain + "$|\\." + _info.domain + "$");
        if (reg.test(host)) {
          set(_info);
          return;
        }
      }
    }
  }, [list, host, set]);
  return info ? info : false;
}