import React from 'react';

import './index.css';
import { useSelector } from '../../hooks';

export default function () {
  let siteInfo = useSelector(state => state.domain && state.domain.length > 0 ? state.domain[0] : false);
  return (
    <div className='ba-footer-container'>
      {(siteInfo && siteInfo.license) && ([
        <label>注册地址: www.{siteInfo.domain} &nbsp;&nbsp;</label>,
        siteInfo.company && (<label>{siteInfo.company} &nbsp;&nbsp;版权所有2016~2024&nbsp;&nbsp;</label>),
        <label key="1">备案/许可证编号：</label>,
        <a key="2" href="http://www.beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">{siteInfo.license}</a>,
        siteInfo.contact && <label>&nbsp;&nbsp;联系方式: {siteInfo.contact}</label>
      ])}
    </div>
  )
}