import React from 'react'
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Button,
  Checkbox
} from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export default () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Divider orientation="center">
        Login
      </Divider>
      <Row justify="center" >
        <Col span={8}>
          <Form>
            <Form.Item name="username" rules={[{ required: true, message: "请输入用户名" }]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                type="username"
                placeholder="用户名"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="密码"
              />
            </Form.Item>
            <Row justify="space-between">
              <Col>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <a className="ant-form-item-control-input" href="/">忘记密码</a>
              </Col>
            </Row>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>Log in</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  )
};